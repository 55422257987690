.inp-check {
    input {
        position: absolute;
        left: -5000px;
        + span {
            position: relative;
            font-size: 14px;
            padding-left: 25px;
            font-weight: normal;
            vertical-align: middle;
            cursor: pointer;
            color: var(--grayTextColor);
            float: left;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                border-radius: 3px;
                border: 1px solid var(--grayTextColor);
                transition: border-color, .2ms ease;
                transition: background, .2s ease;
            }
            &:after {
                content: "\F00C";
                font: normal normal normal 14px/1 FontAwesome;
                color: var(--grayTextColor);
                position: absolute;
                left: 2px;
                top: 1px;
                width: 15px;
                height: 15px;
                font-size: 14px;
                display: none;
            }
            &:hover {
                &:before {
                    background: @lightWhite;
                    border-color: var(--brightYellow);
                }
            }
        }
        &:checked + span {
            &:after {
                display: block;
            }
        }

        &.noDisableEffect {
            &:disabled + span {
                color: #4B4B4B;
                &:before {
                    background: #fff;
                    border-color: #8a8a8a;
                }
            }
        }

        &:disabled + span {
            color: #999;
            &:before {
                background: #e0e0e0;
                border-color: #b3b3b3;
            }
        }
    }
}