#mother.mobile {
    .mobile{
        display: flex;
        flex-direction: column;
        .home{
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 10px auto;
        }
        .time-block-wrap{
            display: flex;
            justify-content: center;
            margin: 10px auto;
        }
    }
    .main-panel {
        width: 100%;
        left: 0;
        margin-top: 0;
        .content-panel{padding-top: 0;}
        .header {
            .time-block-wrap{
                width: 100%;
                padding: 0;
                display: flex;
                justify-content: space-between;
                height: 35px;
                .fast-dial{
                    width: 100%;
                    justify-content: space-around;
                }
            }
        }
        .graph-wrap{
            .chart-container {
                width: 100%;
                padding: 0;
            }
            .graph-inner-wrap{
                .panel{
                    margin: 0 auto;
                    .title-wrap{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        width: fit-content;
                    }
                    .switches{
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        span,a{font-size: 17px}
                        .switch-slider{
                            height: 18px;
                            width: 40px;
                            input:checked+.slider:before {
                                transform: translateX(20px);
                            }
                            .slider{
                                &:before{
                                    height: 18px;
                                    width: 18px;
                                }
                            }
                        }
                        .switch{margin: 0;}
                    }
                    .cntrl-btn{
                        text-decoration: none;
                        color: var(--brightYellow);
                    }
                }
            }
        }
        .footer {
            .footer-block.stat-block {
                justify-content: center;
            }
            .stat-block{height: auto}
        }
        .traceroute-legend{
            margin-top: 20px;
            .title-head{
                justify-content: center;
                padding: 0;
                &__top{
                    .aspath{
                        flex-direction: column;
                        width: 100%;
                        justify-content: center;
                        align-content: center;
                        .title{
                            text-align: center;
                            margin: 0;
                        }
                        ul{
                            margin: 5px 0;
                            justify-content: center;
                        }
                    }
                }
            }

        }
        .trace-data-record{
            .headline{
                display: block;
                width: 100%;
                text-align: center;
                color: white;
                margin-bottom: 15px;
            }
            clear: left;
            padding-top: 10px;
            .trace-box{
                display: flex;
                margin: 0 0 15px 0;
                overflow: hidden;
                border-left: 1px solid var(--brightYellow);
                padding: 0 5px;
                width: 90%;
                position: relative;
                margin: 0 auto;
                margin-bottom: 15px;
                span, div{
                    pointer-events: none;
                }
                a{
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;
                    width: 100%;
                }
                .record{
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    width: calc(~"100% - 45px");
                    span{
                        color: gray;
                        font-size: 12px;
                    }
                    .pl-info{
                        margin-left: 75px;
                    }
                    .in-value{
                        margin-right: 5px;
                    }
                    .text-color{
                        color: var(--brightYellow);
                    }
                    margin-right: 10px;
                    &.description{
                        flex-grow: 0;
                        width: 55px;
                        span{
                            color: var(--brightYellow);
                        }
                    }
                }
                .host-record{
                    display: inline-block;
                    span{
                        color: gray;
                        font-size: 12px;
                        word-break: break-all;
                    }
                }
                .pl-box{
                    width: 45px;
                    height: 35px;
                    border: solid;
                    border-radius: 10px;
                    text-align: center;
                    span{
                        margin: -2px 0 2px 0;
                        width: 100%;
                        display: block;
                    }
                    .small{
                        margin: 0;
                        font-size: 10px;
                        color: var(--grayTextColor)
                    }
                    .val{color: var(--grayTextColor)}
                }
            }
        }
    }
}
