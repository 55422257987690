.select2-container{
    width: 300px !important;
    .select2-selection{
        background: var(--mainBackground);
        color: white;
        &:focus{outline:none};
    }
    .select2-selection__rendered{
        color: var(--grayTextColor) !important;
    }
    .select2-dropdown{
        background: white;
        color: var(--grayTextColor);
        border: none;
        border: solid 1px var(--grayTextColor);
        .select2-search__field{
            margin-top: 10px;
            line-height: 20px;
            border: none;
            color: var(--grayTextColor);
            &:focus{outline: none}
        }
    }
}

a{
    &:hover{
        cursor: pointer;
        color: white;
    }
    text-decoration:none;
}

.select_style, .select_style.active{
    min-width: 110px;
    background: transparent;
    color: @lightWhiteActive;
    &:hover{
        color: white;
    }
    .top{
        padding-left: 20px;
    }
    .selectcon{
        margin-right: 10px;
    }
    .value_wrapper{
        background: @textColor;
        margin-top: 10px;
        box-shadow: none;
        border: none;
        .values{
            padding: 5px 10px 5px 10px;
            label{
                color: white;
                font-size: @fontSizeStandard;
                font-family: @font;
            }

            &:hover, &.active{
                background: @blueHoverLight;
            }
        }
    }
}

.input-debug-count{
    width: 45px;
    height: 30px;
    -webkit-appearance: none;
}

.disabled{
    color: gray;
    text-decoration: none;
    &:hover{
        cursor: none;
    }
}

.red{
    color: @brightRed
}

.green{
    color: @brightGreen;
}

.inline{
    display: inline-block;
}

.error404{
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 auto;
    margin-top: 2%;
    color: white;
    a{
        transition: color 0.6s;
        color:@colorLink;
        &:hover{
            transition: color 0.6s;
            color: lighten(saturate(@colorLink, 25%), 20%);
        }
    }
    h3&.goHome{
        margin-top:6%;
        span {
        letter-spacing: 3px;
        font-size: @fontSizeH2;
        }
    }
    .wrong-params-msg{
        color: lightgray;
        font-weight: normal;
        font-size: @fontSizeStandard;
    }
}

.hidden{display: none;}
.right{float: right;}
.left{float: left}
.upperCase{text-transform: uppercase;}

.error-msg-form{
    padding:3px 7px;
    border-left:1px dotted @red;
    color:inherit;
    font-style:italic;
    margin-bottom:10px;
    opacity:0.8;
    font-size:@fontSizeSmall;
}
.input-green{
    border-bottom:1px solid @green;
    outline: none;
    box-shadow: 0 10px 17px -13px @green;
}
.input-red{
    border-bottom:1px solid @brightRed;
    outline: none;
    box-shadow: 0 10px 17px -13px @brightRed;
}
.submit-butt-disabled{
    background:darken(@white, 30%) !important;
    color:@white !important;
    &:hover, &:focus{
        background:darken(@white, 30%) !important;
    }
}

.confirm-dialog{
    text-align: center;
    width: 50%;
    max-width: 600px;
    max-height: 400px;
    left: 25%;
    z-index: 10;
    position: absolute;
    top: 15%;
    background: white;
    border-radius: 10px;
    padding-top: 50px;

    &.success{
        border: solid 2px @brightGreen;
    }
    &.error{
        border: solid 2px @red;
    }
    .fa{
        display: block;
        font-size: 80px;
        margin-top: 15px;
        &.fa-check-circle{
            color: @brightGreen;
        }
        &.fa-times-circle{
            color: @red;
            color: @red;
        }
    }
}

.content{
    display: block;
    width: 100%;
    float: left;
}

.margin-20-top{
    margin-top: 20px;
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.topPanelShadow(){
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3), 0 1px hsla(0,0%,100%,.1);
}

.lightIcon(@px, @color){
    -webkit-text-stroke: @px @color;
}

.noOutline(){
    &:focus{
        outline: none;
    }
}

.lightText(){
    color: var(--grayTextColorLight);;
    font-weight: 200;
};

.lightAndBrightText(){
    color: var(--grayTextColor);
    font-weight: 200;
};

.borderBoxIt(){
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}


.probeAction(){
    &:hover{
        border: solid 1px #ACCC32;
        color: var(--brightYellow);
        span,i{
            color: var(--brightYellow);
            cursor: pointer;
        }
    }
    &:focus, &.active{
        background: var(--midYellow);
        span{color: white;}
    }
}
.linkAction(){
    &:focus, &.active{
        text-decoration: none;
    }
    &:hover{
        color: var(--brightYellow);
        cursor: pointer;
        text-decoration: underline;
    }
}

#icon-logo{
    width: 112px;
    height: 22px;
}

.invalid-license{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    .wrap{
        display: flex;
        flex-direction: column;
    }
    img{
        height: 40px;
        margin-bottom: 20px;
    }
    .box{
        display: block;
        min-width: 600px;
        text-align: center;
        border: solid 2px red;
        border-radius: 10px;
        font-size: 20px;
        color: rgba(255,255,255,0.7);
        padding: 20px 50px;
        background: rgba(255,0,0,0.15);
        .link{
            color: #25BF72;
        }
        .controls{
            display: flex;
            justify-content: center;
            a{
                padding: 5px 10px;
                border-radius: 5px;
                margin-top: 20px;
                color: #478ad3;
            }
        }
    }
    &.mobile{
        .box{
            min-width: auto;
            width: 90%;
            margin: 0 auto;
            padding: 20px 10px;
        }
    }
}