/* The switch - the box around the slider */
.switch-slider {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 13px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--sliderTrackColor);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 0px;
    bottom: 0px;
    background-color: var(--brightYellow);
    -webkit-transition: .4s;
    transition: .4s;
}

//input:checked + .slider {
//    background-color: var(--brightYellow);
//}

input:focus + .slider {
    box-shadow: 0 0 1px var(--brightYellow);
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}