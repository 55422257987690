.traceRoute-wrap{
    .back{
        font-size: 18px;
        color: @darkGrey;
        i{
            margin-right: 20px;
        }
        &:hover{
            color: white;
        }
    }
    .trace-header{
        position: relative;
        margin: 0 auto;
        display: block;
        width: 80%;
        color: white;
        h1{
            font-size: @fontSizeH1;
            font-weight: 300;
        }
        h2{
            font-size: @fontSizeH2;
            font-weight: 300;
        }
    }
}

.trace-table{
    display: block;
    padding: 0;
    margin: 0 auto;
    position: relative;
    table{
        font-weight: 400;
        width: 100%;
        position: relative;
        margin: 0 auto;
        border: none;
        border-collapse: collapse;
        th {
            letter-spacing: 1px;
            font-weight: 300;
            color: var(--grayTextColor);
            font-size: 13px;
            text-align: left;
            padding: 10px 0 10px 5px;
            &:first-child {
                padding-left: 0;
            }
        }
        tr{
            height: 25px;
            &.light{td{background: var(--traceBackLight)}}
            &.dark{td{background: var(--traceBackground)}}
            &:hover{
                cursor: pointer;
                td{background: var(--traceBackHover)}
            }
        }
        td {
            padding: 5px 2px;
            color: var(--traceText);
            font-weight: 300;
            font-size: 13px;
            &.hop-order {
                width: 10px;
                padding: 3px 10px;
            }
            &.first{border-radius: 5px 0 0 5px;}
            &.last{border-radius: 0 5px 5px 0;}
            &.hop-perc {
                display: flex;
                box-sizing: border-box;
                height: 30px;
            }
            &.hop-time {width: 80px;}
            &.hop-id {width: 150px;}
            &.hop-host{white-space: nowrap;}
        }
        div.bar {
            width:80px;
            height: 17px;
            margin: 0 10px 2px 0px;
            display: flex;
            svg{
                height: 100%;
                width: 8px;
                rect{
                    rx: 2px;
                }
            }
        }
    }
    @media(max-width: 1350px){
        .text-percentage{
            display: none;
        }
    }
}
.traceroute-options{
    width: 100%;
    display: flex;
    .inp-check{
        margin-left: 30px;
        margin-top: 2px;
    }
}
.traceroute-legend {
    margin-top: 20px;
    margin-bottom: 50px;
    .title-head{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        background: var(--graphColor);
        border-radius: 3px;
        box-shadow: var(--panel-shadow);
        box-sizing: border-box;
        padding: 20px 0;
        &__top{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 5px 30px;
            width: 100%;
            .left{
                display: flex;
                flex-direction: column;
                .source{
                    margin-top: 5px;
                    color: var(--link);
                    text-transform: uppercase;
                    font-size: 12px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .left a{
        color: var(--brightYellow);
        .linkAction();
    }

    .light{
        color: rgba(155,158,174,.8);
    }

    .bold{
        color: var(--title);
        font-weight: 700;
    }

    .aspath{
        display: flex;
        flex-direction: column;
        &__row{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }
        &__cta{
            margin-top: 5px;
            font-size: 12px;
            cursor: pointer;
            color: var(--grayTextColor);
            &:hover{cursor: unset;}
            @media(max-width: 600px){display: none}
        }
        .title{
            margin-right: 15px;
            letter-spacing: 1px;
            color: var(--title);
            font-weight: 700;
            line-height: 25px;
            font-size: @fontSize12;
        }
        ul{
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            li{
                color: var(--grayTextColor);
                font-size: 14px;
                font-weight: bold;
                border-radius: 20px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                padding: 0px 10px;
                margin: -1px 5px 10px;
                background: var(--mainBackground);
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: auto;
            }
        }
    }

    .traceroute-detail{
        width: 95%;
        display: block;
        position: relative;
        margin: 0 auto;
    }
    .legend-line-wrap{
        width: 95%;
        margin: 20px auto;
        border-right: 1px solid var(--title);
        border-left: 1px solid var(--title);
    }
    .legend-line{
        margin-left: 60px;
        height: 30px;
        position: relative;
        &-background{
            background: var(--sliderTrackColor);
            margin-bottom: 10px;
        }
        .trace-record {
            display: inline-block;
            height: 27px;
            position: absolute;
            text-align: center;
            border: 1.5px solid var(--midYellow);
            z-index: 1;
            margin-top: 0;
            border-radius: 5px;
            &.active, &:hover {
                border-color: var(--brightYellow);
            }
        }
    }
    .traces{
        margin-top: 20px;
        .trace-data-record{
            margin-top: 20px;
            .time{
                color: white;
            }
        }
    }

    .trace-table-top {
        display: flex;
        justify-content: space-between;
        padding: 0 15px 10px 0;
        .fa-copy{
            color: var(--brightYellow);
        }
    }


    //display: block;
    //float: left;
    //width: 100%;
    //margin-top: 15px;
    //
    //.title-head{
    //    width: 100%;
    //    display: flex;
    //}
    //
    //span{
    //    color: @textColor;
    //    margin: 0;
    //}
    //a{
    //    color: @darkGrey;
    //    &:hover{
    //        color: white;
    //    }
    //}
    //.all-traces{
    //    display: block;
    //    position: absolute;
    //    font-size: 20px;
    //    width: 20px;
    //    left: -30px;
    //    top: -10px;
    //    &.active{
    //        color: @textColor;
    //    }
    //}
    //.show-trace-option{
    //    display: block;
    //}
    //.trace-legend-wrap {
    //    width: 94%;
    //    display: block;
    //    position: relative;
    //    margin: 0 auto;
    //    float: left;
    //    margin-left: 60px;
    //    .trace-table {
    //        margin: 0;
    //        margin-top: 10px;
    //    }
    //    .legend-line {
    //        width: 100%;
    //        border-top: solid 2px white;
    //        float: right;
    //    }
    //    .traces{
    //        height: 100%;
    //        width: 100%;
    //        display: block;
    //        float: left;
    //        margin-top: 20px;
    //    }
    //    .trace-record {
    //        display: inline-block;
    //        height: 15px;
    //        position: absolute;
    //        top: -11px;
    //        border-radius: 10px;
    //        text-align: center;
    //        border: solid @darkGrey;
    //        z-index: 1;
    //        &.active {
    //            border: solid @brightBlue;
    //        }
    //        &:hover {
    //            border: solid white;
    //        }
    //    }
    //    .trace-data-record{
    //        padding-top: 20px;
    //    }
    //}
}