@import "generic/generalElements.less";
@import "generic/checkbox.less";
@import "generic/loader.less";
@import "generic/common.less";
@import "generic/slider.less";
@import "generic/daterangepicker-customization.less";
@import "generic/range-slider.less";
@import "generic/button.less";
@import "generic/loader.less";

@import "lato.less";
@import "mainPanel.less";
@import "libs/select.less";
@import "graph-header.less";
@import "graph-footer.less";
@import "graph.less";
@import "trace.less";
@import "mobile.less";
@import "chart.less";


// variables
@mobileMax: 700px;
@fontSizeH1: 35px;
@fontSizeH2: 25px;
@fontSizeH3: 120%;
@fontSizeStandard: 15px;
@fontSizeSmall: 14px;
@fontSize12: 12px;
@fontSize13: 13px;

@white:#fff;
@lightWhite: rgba(255,255,255,0.3);
@lightWhiteActive: rgba(255,255,255,0.7);
@dimGrey: #1C282D;
@lightGrey: #BBBDBE;
@darkGrey: #777777;
@midGrey:#1F2731;

@dimBlue : #2A3746;
@textColor: #486F97;
@brightBlue: #0451A0;
@blue: #0451A0;

@brightGreen: #19e880;
@brightRed: #FF0202;

@color: #04151f;
@colorLink: @blue;
@colorHover: #fff;

@blueTabActive: #0074DB;
@blueHover: #1b9be9;
@blueHoverLight: #557BA5;
@blueClick: #1285ca;
@red: #e64c3c;
@redHover: #f2321e;
@redClick: #d82816;


@green: #32cd2e;
@greenLight: #88f086;

@font: 'Lato',sans-serif;
@fontTitles: @font;
@fontSize: 15px;
@lineHeight: 20px;
@sidePanelSection: 16px;

//zarovnani elementu v main panel dle IP
@zarovnaniElemMP: 30px;
@graphLeftMargin: 50px;
// Paths
@img: '../img/';
@ieRGBA: '../img/rgba.php';
@bg: '../img/bg/all.png';

// Breakpoints
@mobileLS: 480px;
@mobileMax: 699px;
@tablet: 700px;
@tabletMax: 999px;
@desktop: 1000px;
@full: 1600px;

@modalBackground:#426C9B;
@radius:10px;
@datepickerActive: #6da5e3;//#9db3cb; //#72ACEC;

:root{
    --graphColor: #3F4155;
    --title: white;
    --brightYellow: #ADC65C;
    --midYellow: rgba(173,198,92,0.4);
    --darkYellow: #475131;
    --grayTextColor: #C9CBD8;
    --grayTextColorLight: rgba(155,158,174,0.3);
    --mainBackground: #22263B;
    --dateRangePickerBackground: #5A5E6F;
    --dateRangePickerColor: white;
    --dateRangePickerSecondColor: rgba(255,255,255,0.3);
    --traceText: #9E9FAA;
    --traceBackground: transparent;
    --traceBackHover: rgba(255,255,255,0.15);
    --traceBackLight: rgba(255,255,255,0.05);
    --sliderTrackColor: rgba(155,158,174,0.3);
}

.theme-light{
    --graphColor: #FFF;
    --title: black;
    --brightYellow: #ADC65C;
    --midYellow: rgba(173,198,92,0.4);
    --darkYellow: #475131;
    --grayTextColor: #22253D;
    --grayTextColorLight: rgba(155,158,174,0.3);
    --mainBackground: #F4F5F7;
    --traceText: #5A5D71;
    --traceBackground: transparent;
    --traceBackHover: rgba(255,255,255,0.15);
    --traceBackLight: #E8E9EF;
    --sliderTrackColor: rgba(201,203,214, 0.1);
}

.rounded-corners (@radius:10px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}

.calculated-width(@value, @subtract) {
    width: -webkit-calc(@value - @subtract);
    width:    -moz-calc(@value - @subtract);
    width:         calc(@value - @subtract);
}

::-webkit-scrollbar
{
    width: 6px;  /* for vertical scrollbars */
    height: 6px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track
{
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb
{
    background: rgba(4, 81, 160, 0.5);
}

html, body, #routeContent, #father, #mother{
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    min-height: 100%;
    margin: 0;
    font-family: @font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.home{
    text-align: center;
    color: white;
    float: left;
    width: 100%;
    display: flex;
    #pow{
        padding-top: 1px;
        font-size: 20px;
        color: gray;
        margin-right: 10px;
    }
    #logo {
        padding-top: 5px;
        font-size: 25px;
        font-weight: bold;
    }
    #text-logo{
        display: block;
        color: white;
        font-weight: bold;
        font-size: 15px;
        span{
            font-weight: 100;
            margin-left: 2px;
            letter-spacing: 2px;
        }
    }
}

.highcharts-axis-labels {
    &.highcharts-xaxis-labels,  &.highcharts-yaxis-labels{
        text{
            font-family: @font !important;
        }
    }

}

d #mother .iframe{
    .head-banner{margin-top: 10px}
    .graph-wrap .graph-inner-wrap .panel {margin: 10px auto}
}
