body #mother{
    display: flex;
}

body{
    min-height: 100%;
    margin: 0;
}

#no-portrait-orient{
    display: none;
}
@media (max-width: 500px) and (orientation:portrait){
    #no-portrait-orient{
        width: 100%;
        height: 100%;
        background: rgba(67,75,80,0.9);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: table;
        text-align: center;
        color: white;
            h1{
                line-height: 40px;
                vertical-align: middle;
                display: table-cell;
        }
    }
}

.chart-container-load {
    display: inline-block;
    width: 94%;
    height: 400px;
    margin: 0 auto;
    text-align: center;
}

body {
    font-family: @font;
    background: var(--mainBackground);
    button{
        font-family: @font;
        font-size: @fontSizeStandard;
    }
    #mother{
        display: flex;
        width: 100%;
        background: var(--mainBackground);
        .main-panel{
            margin-top: 30px;
            width: 100%;
            position: relative;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            background: var(--mainBackground);
            li a{
                font-size: @fontSizeStandard;
            }
            &.detail-ip-probes{
                display: flex;
                flex-direction: column;
            }
            @media(max-width: @mobileMax){
                width: 100%;
            }
        }
        .generatedContent{
            display: flex;
            flex-direction: column;
            margin-top: 30px;
        }

        .head-banner, .foot-banner{
            display: flex;
            width: 90%;
            margin: 0 auto;
        }
        .head-banner{
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            .powered{
                float: right;
                color: white;
                width: 300px;
                img{
                    width: 170px;
                    height: 30px;
                }
            }
            &.mob{
                width: 100%;
                .powered{
                    width: 100%;
                }
            }
        }
        .foot-banner{
            background-image: url(../less/img/banners/banner_BOT_dark.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            height: 164px;
            max-width: 1000px;
            margin: 0 auto;
        }

        .banner{
            display: block;
            width: 100%;
            text-align: center;
            padding-top: 10px;
            color: gray;
            font-size: 25px;
        }
    }
}

.ip-closer-info{
    font-size: 20px;
    color: @lightGrey;
    text-align: center;
    span{
        line-height: 30px;
    }
}