.graph-wrap{
    display: flex;
    flex-direction: column;
    .graph-inner-wrap{
        background: var(--graphColor);
        width: 95%;
        margin: 0 auto;
        border-radius: 3px;
        margin-top: 10px;
        .panel{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 90%;
            .switches{
                display: flex;
                padding-top: 2px;
                .switch{
                    display: flex;
                    margin-left: 10px;
                    margin-right: 10px;
                    .switch-slider{
                        margin: 1px 7px 0;
                    }
                    span{
                        font-size: @fontSize13;
                        color: rgba(155,158,174,1);
                        .linkAction();
                        &.active{color:var(--brightYellow);}
                    }
                }
            }
            .title-wrap{
                display: flex;
                overflow: hidden;
                .title{
                    .ip-info{
                        color: var(--title);
                        margin-right: 10px;
                        font-size: 16px;
                        letter-spacing: 0;
                        text-transform: uppercase;
                        &.no-title{
                            color: rgba(155,158,174,1);
                        }
                    }
                    .alias-info{
                        font-size: @fontSize13;
                        color: rgba(155,158,174,1);
                    }
                }
                .ip-settings {
                    margin-left: 10px;
                    color: var(--brightYellow);
                    display: flex;
                    .fa {
                        color: var(--brightYellow);
                        font-size: 20px;
                        margin-right: 5px;
                    }
                }
            }
            .controls-wrap{
                display: flex;
                flex-wrap: wrap-reverse;
                .range-slider{
                    display: flex;
                    width: 100px;
                    margin-right: 25px;
                    span{
                        color: var(--brightYellow);
                        font-size: 17px;
                        line-height: 20px;
                        .lightIcon(1px, var(--panel-background));
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:hover{
                            text-decoration: none;
                            cursor: pointer;
                        }
                    }
                    span.big{
                        font-size: 35px;
                    }
                }
            }
            .share-btn{
                color: var(--brightYellow);
                font-size: 16px;
                i{margin-right: 10px;}
                .linkAction();
            }
            .title-wrap, .switches, .share-ip{
                margin: 10px 0;
            }
        }
        .probe-title-wrap{
            margin: 30px 80px;
            width: fit-content;
            color: white;
        }
    }
    .chart-container {
        display: block;
        width: 95%;
        height: 400px;
        margin: 0 auto;
    }
    &.dash-view {
        .graph-inner-wrap {
            background: transparent;
            .highcharts-background {
                fill: transparent !important;
            }
        }
    }
}
.probe-graph-wrap.multiple{
    margin-top: 30px;
}
