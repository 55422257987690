.select-probe-wrap {
    display: inline-block;
}
.header {
    display: flex;
    justify-content: space-between;
    .header-block {
        display: flex;
        margin: 0 auto;
        width: 90%;
    }
    .side-action-button{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: transparent;
        border: solid 1px var(--grayTextColor);
        box-sizing: border-box;
        display: flex;
        .chevron{
            display: block;
            color: var(--brightYellow);
            width: 16px;
            height: 15px;
            border-radius: 50%;
            text-align: center;
            svg {
                width: 8px;
                height: 15px;
                padding-top: 1px;
                path{
                    fill: var(--grayTextColor);
                }
            }
            &.up{
                transform: rotate(180deg);
                svg {
                    padding-top: 0px;
                }
            }
        }

        &:hover{
            background: var(--midYellow);
            border-color: var(--midYellow);
            .lightIcon(2px, var(--midYellow));
        }
    }
}
#mother, #mother.header {
    .time-block-wrap {
        display: flex;
        align-items: center;
        padding-top: 5px;
        height: 100%;
        width: fit-content;
        margin: 0;
        box-sizing: border-box;
        height: 50px;
        .icon-calendar {
            margin: 9px 10px 0 3px;
            svg path{
                fill: var(--grayTextColor)Light;
            }

        }
        .fast-dial {
            display: flex;
            padding-top: 3px;
            a {
                font-size: @fontSize13;
                color: var(--grayTextColor);
                border-radius: 20px;
                padding: 3px 5px;
                letter-spacing: 1px;
                height: 15px;
                border: solid 1px transparent;
                margin-right: 5px;
                .probeAction();
                &.active {
                    color: white;
                    background: var(--midYellow);
                    border-color: var(--brightYellow);
                }
            }
        }
        .calendar-block {
            margin-left: 10px;
            .reset-zoom {
                a {
                    color: var(--brightYellow);
                    display: block;
                    padding: 5px 20px;
                    border: solid 1px transparent;
                    &:hover {
                        border: solid 1px var(--brightYellow);
                        border-radius: 20px;
                        color: var(--brightYellow)
                    }
                }
            }
            .side-action-button{
                margin-top: 6px;
            }
            .inner-block {
                height: 100%;
            }
        }
    }
    .epoch-matrix-block{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20px;

    }
    .matrix{
        display: flex;
        width: fit-content;
        .matrix-center{
            display: flex;
            flex-direction: column;
        }
        .slave-name{
            padding-right: 15px;
            text-align: right;
        }
        .control-head{
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            span{color: var(--grayTextColor);}
        }
        .table-wrap{
            display: flex;
            &.absolute{
                display: block;
                position: absolute;
                z-index: 10;
                max-height: 400px;
                background: #22263b;
                padding: 30px 20px;
                border-radius: 10px;
                box-sizing: border-box;
                border: 1px solid #353A4E;
                margin-top: 30px;
                right: 5%;
                table{
                    overflow-y: scroll;
                }
            }
            &.bars{
                table{
                    tr:hover{
                        cursor: pointer;
                        opacity: 1;
                    }
                }
            }
            &.matrix{
                margin-top: 65px;
            }
        }
        table{
            border-collapse: collapse;
            border: none;
            display: block;
            height: 100%;
            padding-right: 10px;
            thead{
                td{
                    color: var(--grayTextColor);
                    font-weight: 300;
                    font-size: 13px;
                    text-align: center;
                    border: none;
                    padding: 0;
                    padding-bottom: 2px;
                }
            }
            tbody{
                th{
                    color: var(--grayTextColor);
                    font-size: 13px;
                    line-height: 15px;
                    height: 15px;
                }
                tr{
                    opacity: 0.75;
                }
                svg,rect{
                    width: 20px;
                    height: 10px;
                    &.first{border-radius: 10px 0 0 10px;}
                    &.last{border-radius: 0 10px 10px 0;}
                }
            }
        }
        &.type-matrix{
            @rectSize: 10px;
            table{
                border-collapse: collapse;
                border: none;
                thead{
                    td{
                        border-style : hidden!important;
                    }
                }
                tbody{
                    tr{display: flex}
                    tr,th{
                        line-height: @rectSize;
                        height: @rectSize;
                        padding: 0;
                        margin: 0;
                    }
                    th{
                        rect{opacity: 0.7;}
                        &:hover{
                            rect{
                                opacity: 1;
                                cursor: pointer;
                            }
                        }
                        &.th-time{
                            width: 48px;
                            font-size: 11px;
                            text-align: right;
                            padding-right: 10px;
                            color: rgba(155,158,174,.7);
                        }
                    }
                    svg,rect{
                        width: @rectSize;
                        height: @rectSize;
                    }
                }
            }
        }
        .switches{
            display: flex;
            padding-top: 3px;
            margin: 0 20px;
            .switch{
                display: flex;
                margin-right: 25px;
                .switch-slider{
                    margin: 1px 10px;
                }
                span{
                    font-size: @fontSize13;
                    color: var(--grayTextColor)Light;
                    .linkAction();
                    &.active{color:var(--brightYellow);}
                }
            }
        }
        .desc{
            color: var(--grayTextColor);
            text-align: center;
        }
    }
}
